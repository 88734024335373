import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import Logo from "../../assets/logo.png"
import Typography from "../ui/Typography"

import "../../fonts/styles.css"
import "../layouts/layout.css"
import NotificationContainer from "../Notifications/NotificationContainer"
import { useTranslationContext } from "../../context/TranslationContext"
import { handleUrlPrefix } from "../../utils/translations-util"

const CheckoutContainer = styled.div`
  position: relative;
  display: flex;

  height: 100%;
  min-height: 700px;
`

const NavItem = styled.div`
  ${Typography.Medium}
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-self: ${(props) => props.justifySelf};
  &:hover {
    color: ${(props) => props.theme.colors.textHover};
  }
`

const NavBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  display: grid;
  ${(props) => props.theme.responsive.padding.x}
  grid-template-columns: 1fr;

  height: ${(props) => props.theme.navbar.height};
  z-index: 2000;
  background-color: white;
`

const HeaderLogo = styled.img`
  height: 18px;
`

const CheckoutLayout = ({ children, showNavbar }) => {
  const { translationContext } = useTranslationContext()
  const frontPageUrl = handleUrlPrefix(translationContext.locale, "") ?? "/"

  return (
    <CheckoutContainer>
      {showNavbar && (
        <NavBar>
          <NavItem justifySelf="center">
            <Link style={{ lineHeight: "18px" }} to={frontPageUrl}>
              <HeaderLogo alt="Tekla Fabrics Logo" src={Logo} />
            </Link>
          </NavItem>
        </NavBar>
      )}
      <NotificationContainer />
      {children}
    </CheckoutContainer>
  )
}

CheckoutLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CheckoutLayout
