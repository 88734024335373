import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import qs from "query-string"

import Medusa from "../../services/api"
import SEO from "../../components/seo"
import LoadingSpinner from "../../components/ui/LoadingSpinner"
import Typography from "../../components/ui/Typography"
import CheckoutLayout from "../../components/checkout/checkout-layout"

const DetailContainer = styled.div`
  margin: 0 auto;
  margin-top: 5rem;
  a {
    color: black;
  }
`

const Details = styled.div`
  h1 {
    ${Typography.Large}
    font-weight: normal;
  }
`

class CheckoutDone extends React.Component {
  state = {
    order: {},
  }

  async componentDidMount() {
    const { pct } = qs.parse(this.props.location.search)

    let order

    try {
      order = await Medusa.orders.retrieveByCartId(pct)
      this.setState({
        order: order.data.order,
      })
    } catch (error) {
      await Medusa.cart.completeCart(pct).then(({ data }) => {
        this.setState({
          order: data.data,
        })
      })
    }
  }

  render() {
    const { order } = this.state
    return (
      <CheckoutLayout>
        <SEO title="Order completed" />
        {!order.id ? (
          <LoadingSpinner />
        ) : (
          <DetailContainer>
            {order && (
              <div>
                <Details>
                  <h1>Thank you, {order.shipping_address.first_name}!</h1>
                  <div>
                    Your payment has been received. We will process your order
                    as soon as possible.
                  </div>
                </Details>
              </div>
            )}
            <Link to="/">Back to teklafabrics.com</Link>
          </DetailContainer>
        )}
      </CheckoutLayout>
    )
  }
}

export default CheckoutDone
